import { Component, Inject, Input, PLATFORM_ID } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { IPopup } from "../../../../../core/models/application/popup.model";
import { EPopupAttachmentType } from "../../../../../core/enums/popup-attachment-type.enum";
import { isPlatformBrowser } from "@angular/common";

@Component({
  selector: 'app-dynamic-popup-modal',
  templateUrl: './dynamic-popup-modal.component.html',
  styleUrl: './dynamic-popup-modal.component.scss'
})
export class DynamicPopupModalComponent {
  @Input() popup?: IPopup;
  attachmentType = EPopupAttachmentType;
  isBrowser: boolean;

  constructor(
    public modal: NgbActiveModal,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  goToUrl() {
    if (this.popup?.url && this.isBrowser && (typeof window !== 'undefined')) {
      window.open(this.popup.url, "_blank");
    }
  }

}
