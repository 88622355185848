import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { EPopupViewArea } from "../../core/enums/popup-view-area.enum";
import { PopupService } from "../../core/services/application/popup.service";
import { take } from "rxjs";
import { IPopup } from "../../core/models/application/popup.model";
import { EPopupDisplayStrategy } from "../../core/enums/popup-display-strategy.enum";
import {
  DynamicPopupModalComponent
} from "../modules/theme/components/dynamic-popup-modal/dynamic-popup-modal.component";
import { isPlatformBrowser } from "@angular/common";

@Injectable()
export class PopupModalService {
  isBrowser = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private modalService: NgbModal,
    private service: PopupService,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  openModalWithViewArea(viewArea: EPopupViewArea) {
    if (!this.isBrowser) {
      return;
    }
    this.service.getPopupsWithViewArea(viewArea)
      .pipe(take(1))
      .subscribe(r => {

        const popups = r.data as IPopup[];
        const availablePopup = popups.find(p => (p.displayStrategy === EPopupDisplayStrategy.ALWAYS) || ((p.displayStrategy === EPopupDisplayStrategy.SINGLE_TIME) && (!p.userViewHistory)));
        if (availablePopup) {
          const modalRef = this.modalService.open(DynamicPopupModalComponent, {
            size: 'xl',
            keyboard: true,
            animation: true,
            centered: true,
          });

          modalRef.componentInstance.popup = availablePopup;
        }
      })
  }
}
