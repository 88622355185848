<div *ngIf="popup" class="modal-header d-flex justify-content-between align-items-center pt-3 pb-2">
  <p class="color-gray-950 fw-800 fs-xlarge" style="letter-spacing: -0.72px;">
    {{popup?.title}}
  </p>
  <a class="clickable color-gray-950 fw-800 fs-xlarge" (click)="modal.close()">
    <gray-icon icon="close"></gray-icon>
  </a>
</div>
<div class="modal-body" *ngIf="popup">
  <div *ngIf="popup.attachments.length == 1">
    <div class="w-100">
      <img
        *ngIf="popup.attachments[0].type === attachmentType.IMAGE"
        class="popup-img" [class.clickable]="popup?.url"
        (click)="goToUrl()"
        alt="" [src]="popup.attachments[0].thumbnails.large">
      <app-static-video-player
        *ngIf="popup.attachments[0].type === attachmentType.VIDEO"
        [videoSrc]="popup.attachments[0].resourceUrl"
      ></app-static-video-player>
    </div>
  </div>

  <div *ngIf="popup.attachments.length > 1">
    <ngb-carousel [interval]="0" [showNavigationArrows]="true" [showNavigationIndicators]="false" class="community-gallery">
      <ng-template *ngFor="let attachment of popup.attachments; let i = index;" ngbSlide>
        <div class="d-flex w-100 justify-content-center">
          <img
            *ngIf="attachment.type === attachmentType.IMAGE"
            class="popup-img" [class.clickable]="popup?.url"
            (click)="goToUrl()"
            alt="" [src]="attachment.thumbnails.large">

          <app-static-video-player
            class="w-100"
            *ngIf="attachment.type === attachmentType.VIDEO"
            [videoSrc]="attachment.resourceUrl"
          ></app-static-video-player>
        </div>
      </ng-template>
    </ngb-carousel>

  </div>

  <div [class.clickable]="popup?.url" (click)="goToUrl()" *ngIf="popup?.content" [innerHTML]="popup.content"></div>
</div>
