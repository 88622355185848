import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";
import { EPopupViewArea } from "../../enums/popup-view-area.enum";

@Injectable()
export class PopupService {
  private apiPrefix = '/client/popup';

  constructor(
    private api: ApiService
  ) {
  }

  getPublicPopups() {
    return this.api.get(`${this.apiPrefix}/public`);
  }

  getPopupsWithViewArea(viewArea: EPopupViewArea) {
    return this.api.get(`${this.apiPrefix}/me?viewArea=${viewArea}`);
  }
}
